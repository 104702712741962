<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs'
import { useQuery } from '@vue/apollo-composable'
import { OrderDocument, type OrderStatus } from '@/gql/generate/graphql'

import HistoryRefillList from '@/components/HistoryRefill/HistoryRefillList.vue'
// import BannerSteamGifts from '@/components/BannerSteamGifts.vue'
import WeaponInfo from './components/WeaponInfo.vue'
import InfoStatusBlock from './components/InfoStatusBlock.vue'
import Button from '@/components/UI/Button.vue'
import Skeleton from '@/components/UI/Skeleton.vue'

const route = useRoute()
const uuid = route.params.id as string

const { result, loading, refetch } = useQuery(OrderDocument, {
  uuid
})

watch(
  () => route.params.id,
  (newUuid) => {
    refetch({
      uuid: newUuid as string
    })
  }
)

const orderType = computed(() => {
  if (result.value?.order.type === 'skins') {
    return 'refill-login'
  }

  if (result.value?.order.type === 'steam') {
    return 'refill-login'
  }

  return 'refill-login'
})

const infoStatus = computed(() => {
  if (result.value?.order.status === 'created') {
    return {
      title: 'Заказ создан',
      icon: 'i-custom-order-approve',
      description: 'После оплаты баланс поступит сразу к вам на Steam аккаунт.',
      titleBlock: 'Ожидаем оплаты',
      descriptionBlock:
        'Заказ успешно создан и ожидает оплаты. Если вы уже произвели оплату, пожалуйста, дождитесь обновления статуса заказа.',
      button: {
        type: 'a',
        href: result.value?.order.paygateUrl,
        text: 'Оплатить',
        icon: 'i-custom-external'
      }
    }
  }

  if (result.value?.order.status === 'processing') {
    return {
      title: 'Отправляем деньги',
      icon: 'i-custom-loader animate-spin',
      description: 'Баланс поступит сразу к вам на Steam аккаунт',
      titleBlock: 'Отправляем деньги',
      descriptionBlock: 'Скоро к вам на Steam аккаунт поступят деньги, пожалуйста подождите.'
    }
  }

  if (result.value?.order.status === 'finished') {
    return {
      title: 'Деньги зачислены',
      icon: 'i-custom-checkmark',
      description: 'Баланс уже у вас на Steam аккаунте.',
      titleBlock: 'Деньги зачислены',
      descriptionBlock: 'Деньги уже зачислены к вам на Steam аккаунт. Проверьте баланс.'
    }
  }

  return {
    title: 'Произошла ошибка',
    icon: 'i-custom-attention text-colors-red',
    description: 'Если у вас возникли вопросы, пожалуйста, обратитесь в службу поддержки.',
    titleBlock: 'Ошибка',
    descriptionBlock:
      'Если ошибка не решена в течение 24 часов, пожалуйста, обратитесь в службу поддержки.'
  }
})
</script>

<template>
  <main class="mt-24px max-w-760px w-full mx-auto flex flex-col gap-y-40px flex-auto">
    <section
      v-if="loading"
      class="grid grid-cols-2 gap-8px <tablet:gap-y-56px <tablet:grid-cols-1 px-20px flex-auto mb-40px <tablet:mb-16px"
    >
      <div>
        <Skeleton class="w-180px h-52px bg-dark-200 rounded-12px" />

        <Skeleton class="mt-32px mb-8px w-100px h-48px bg-dark-200 rounded-12px" />
        <Skeleton class="w-140px h-22px bg-dark-200 rounded-8px" />

        <Skeleton class="mt-32px mb-16px w-160px h-32px bg-dark-200 rounded-12px" />
        <Skeleton class="w-250px h-22px bg-dark-200 rounded-8px" />
      </div>
      <div>
        <Skeleton class="h-255px bg-dark-200 rounded-12px" />
        <Skeleton class="mt-20px h-79px bg-dark-200 rounded-12px" />
      </div>
    </section>
    <section
      v-else-if="result?.order"
      class="grid grid-cols-2 gap-8px <tablet:gap-y-56px <tablet:grid-cols-1 px-20px flex-auto mb-40px <tablet:mb-16px"
    >
      <div class="relative tablet:pr-20px">
        <div
          class="grid grid-cols-[auto_1fr] gap-x-8px gap-y-4px items-center <tablet:items-start <tablet:grid-cols-[42px_1fr_42px] w-full <tablet:grid-rows-[auto_auto]"
        >
          <router-link
            :to="{ name: orderType }"
            class="<tablet:w-42px <tablet:h-42px <tablet:flexc <tablet:rounded-12px <tablet:bg-dark-400 <tablet:row-span-2 &hover:text-light-100 transition-colors"
          >
            <i class="i-custom-chevron-left text-16px"></i>
          </router-link>
          <span class="text-16sb <tablet:text-center">Заказ {{ result.order.id }}</span>
          <div class="tablet:hidden"></div>
          <span class="tablet:col-span-2 <tablet:text-center text-14m text-light-100">
            {{ dayjs(result.order.createdAt).format('DD.MM.YY (HH:mm:ss)') }}
          </span>
        </div>

        <div
          class="heading-40sb mt-32px mb-8px <tablet:text-center"
          :class="result.order.status === 'failed' ? 'text-colors-red' : 'text-colors-green'"
        >
          {{
            result.order.amount
              .toLocaleString('ru-RU', {
                maximumFractionDigits: 2
              })
              .replace(',', '.')
          }}₽
        </div>
        <div class="text-14m text-light-100 <tablet:text-center">Сумма Пополнения</div>

        <div class="text-20sb mt-32px mb-16px flex items-center gap-x-10px <tablet:justify-center">
          <i v-if="infoStatus.icon" class="text-18px" :class="infoStatus.icon"></i>
          {{ infoStatus.title }}
        </div>

        <p class="text-14m text-light-100 <tablet:text-center">
          {{ infoStatus.description }}
        </p>

        <img
          src="/img/rarity/white.svg"
          alt="rarity-white"
          class="w-356px h-287px absolute top-0 left-0 -z-1 <tablet:w-396px <tablet:h-318px <tablet:left-1/2 <tablet:-translate-x-1/2 <tablet:-top-55px"
        />
      </div>
      <div class="flex flex-col gap-y-8px">
        <InfoStatusBlock :title="infoStatus.titleBlock">
          {{ infoStatus.descriptionBlock }}

          <Button
            v-if="infoStatus.button?.href"
            as="a"
            :href="infoStatus.button.href"
            target="_blank"
            color="dark"
            class="w-full mt-16px block"
          >
            {{ infoStatus.button.text }}
            <i class="text-16px" :class="infoStatus.button.icon"></i>
          </Button>
        </InfoStatusBlock>

        <div class="p-20px rounded-12px flex flex-col gap-y-4px bg-dark-100 text-12sb relative">
          <span class="text-light-200 uppercase">STEAM ЛОГИН</span>
          <span>{{ result.order.identifier }}</span>

          <img
            src="/img/user-big.png"
            srcset="/img/user-big@2.png 2x"
            alt="user big"
            class="absolute bottom-0 right-20px"
          />
        </div>

        <!-- <template>
          <div class="flex flex-col gap-y-8px">
            <div class="flex items-center justify-between text-light-200">
              <span class="text-14m">Предметы</span>

              <div class="flex items-center gap-x-8px text-14sb">
                Цены по Buy Order
                <i class="i-custom-chevron-down text-16px"></i>
              </div>
            </div>
            <WeaponInfo
              img="/img/weapons/1.png"
              name="★ Нож выживания | Вороненая сталь"
              status="received"
              :amount="1740"
            />
            <WeaponInfo
              img="/img/weapons/2.png"
              name="★ Нож выживания | Вороненая сталь если вдруг будет длинное название"
              status="sent"
              :amount="356"
            />
            <WeaponInfo
              img="/img/weapons/3.png"
              name="★ Нож выживания | Вороненая сталь"
              status="send"
              :amount="26"
            />
          </div>
        </template> -->
        <!-- <div
          class="mt-16px bg-dark-100 rounded-12px px-20px py-12px flex items-center justify-between relative z-1 overflow-hidden"
        >
          <div class="flex flex-col gap-y-2px">
            <span class="text-12sb text-light-200 uppercase">ИТОГО НА БАЛАНС</span>
            <span class="text-16sb"
              >∼{{
                result.order.amount
                  .toLocaleString('ru-RU', {
                    maximumFractionDigits: 2
                  })
                  .replace(',', '.')
              }}
              ₽</span
            >
          </div>
          <img
            src="/img/steam-coin.png"
            srcset="/img/steam-coin@2.png 2x"
            alt="steam coin"
            class="w-54px h-55px"
          />

          <div
            class="absolute right-0 top-0 -z-1 w-184px h-85px grid grid-rows-4 justify-items-end"
          >
            <div class="w-161px bg-gradient-to-l from-dark-200"></div>
            <div class="w-86px bg-gradient-to-l from-dark-200"></div>
            <div class="w-128px bg-gradient-to-l from-dark-200"></div>
            <div class="w-full bg-gradient-to-l from-dark-200"></div>
          </div>
        </div> -->
      </div>
    </section>
    <section v-else class="flex-auto flexc flex-col gap-y-12px text-40px text-dark-500">
      Данный заказ не найден

      <Button as="router-link" :to="{ name: 'refill-login' }" class="w-200px" color="dark"
        >На главную</Button
      >
    </section>

    <HistoryRefillList class="w-full px-20px" />
    <!-- <BannerSteamGifts class="mobile:mx-20px" /> -->
  </main>
</template>

<style scoped></style>
