<script setup lang="ts">
import { ref, computed, watch, reactive } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import { useRouter } from 'vue-router'
import posthog from 'posthog-js'
import { type PaymentProvider, CreateOrderDocument } from '@/gql/generate/graphql'

import { modals } from '@/components/Modal'
import { notificationAdd } from '@/store/toast'
import { goToLink } from '@/composables'
import { errorApolloHandler, errorNotification } from '@/composables/errors'

import InputMoney from '@/components/UI/InputMoney.vue'
import MethodPayment from './MethodPayment.vue'
import ButtonTag from '@/components/UI/ButtonTag.vue'
import Button from '@/components/UI/Button.vue'
import Skeleton from '@/components/UI/Skeleton.vue'
import Spinner from '@/components/UI/Spinner.vue'

const props = defineProps<{
  paymentMethods?: PaymentProvider[]
  loading: boolean
  minMaxAmount: number[]
  enabled: boolean
  type: 'login' | 'skins' | 'gift'
}>()

const router = useRouter()

const selectedMethodId = ref<number | undefined>(props.paymentMethods?.[0].id)
const selectedMethodCommission = computed(
  () =>
    props.paymentMethods?.find((method) => method.id === selectedMethodId.value)?.commission ?? 1
)
const amount = ref<number | ''>(500)
const identifier = ref('')

const percent = ref(50)
const percentCalculated = computed(() => -1 * (51 + (50 / 100) * percent.value))

const lastIdentifierRefill = ref(localStorage.getItem('refillLogin'))
const loadingUser = ref(false)
const visibleAmountReplenish = ref(true)

const errors = reactive({
  login: '',
  amount: ''
})
function clearErrors() {
  errors.login = ''
  errors.amount = ''
}

watch(
  () => props.loading,
  () => {
    selectedMethodId.value = props.paymentMethods?.[0].id
  },
  {
    once: true
  }
)

const { mutate: mutatePay, loading: loadingPay } = useMutation(CreateOrderDocument)

function onPay() {
  if (!selectedMethodId.value) return

  posthog.capture('click:payment_form:on_pay')

  clearErrors()
  if (!amount.value) {
    errors.amount = 'Введите сумму'
    posthog.capture('error:payment_form:on_pay', { error: 'amount' })
  }

  if (!identifier.value) {
    errors.login = 'Поле не может быть пустым'
    posthog.capture('error:payment_form:on_pay', { error: 'login' })
  }

  const [minPrice, maxPrice] = props.minMaxAmount
  const amountFinal = visibleAmountReplenish.value
    ? amount.value || 0
    : (amount.value || 0) / selectedMethodCommission.value

  const minPriceFinal = minPrice / selectedMethodCommission.value
  const maxPriceFinal = maxPrice / selectedMethodCommission.value

  if (amountFinal < minPriceFinal) {
    errors.amount =
      `Минимальная сумма ` +
      (visibleAmountReplenish.value
        ? `пополнения ${Math.ceil(minPriceFinal)}`
        : `оплаты ${minPrice}`)

    posthog.capture('error:payment_form:on_pay', {
      error: 'min_price',
      amount: amount.value,
      replenishment: visibleAmountReplenish.value
    })
  } else if (amountFinal > maxPriceFinal) {
    errors.amount =
      `Максимальная сумма ` +
      (visibleAmountReplenish.value
        ? `пополнения ${Math.floor(maxPriceFinal)}`
        : `оплаты ${maxPrice}`)

    posthog.capture('error:payment_form:on_pay', {
      error: 'max_price',
      amount: amount.value,
      replenishment: visibleAmountReplenish.value
    })
  }

  if (!Object.values(errors).every((error) => !error)) return

  posthog.capture('request:payment_form:on_pay', {
    amount: amount.value,
    replenishment: visibleAmountReplenish.value
  })

  mutatePay({
    amount: amountFinal,
    providerId: selectedMethodId.value,
    identifier: identifier.value,
    type: 'steam'
  })
    .then((result) => {
      if (result?.data?.createOrder.status === 'created' && result.data.createOrder.paygateUrl) {
        localStorage.setItem('refillLogin', identifier.value)
        goToLink(result.data.createOrder.paygateUrl)
        router.push({ name: 'history', params: { id: result.data.createOrder.uuid } })
        posthog.capture('response:payment_form:on_pay', {
          result: true
        })
        return
      }

      throw new Error('Result false')
    })
    .catch((err) => {
      const { errorCode } = errorApolloHandler(err)
      posthog.capture('response:payment_form:on_pay', {
        result: false
      })
      if (errorCode === 'INVALID_STEAM_LOGIN') {
        return modals.InvalidSteamLogin()
      }
      errorNotification(err)
    })
}

function onFocusClear(value: 'amount' | 'login') {
  errors[value] = ''
}

function onSelectAmount(value: number) {
  errors.amount = ''
  posthog.capture('click:payment_form:select_amount', { amount: value })
  amount.value = value
}

function onChangeAmountReplenish() {
  visibleAmountReplenish.value = !visibleAmountReplenish.value
  posthog.capture('click:payment_form:change_amount_replenish', {
    visibleAmountReplenish: visibleAmountReplenish.value
  })
}

function onWhereGetLogin() {
  posthog.capture('click:payment_form:where_get_login')
  modals.WhereGetLogin()
}

function onLastIdentifierRefill() {
  posthog.capture('click:payment_form:last_identifier_refill')
  identifier.value = lastIdentifierRefill.value || ''
}
</script>

<template>
  <section class="flex flex-col max-w-440px w-full">
    <div
      class="p-20px rounded-16px mobile:bg-dark-100 flex flex-col gap-y-20px <mobile:px-0 <mobile:pt-12px"
    >
      <div class="flex flex-col gap-y-8px">
        <div class="flex justify-between text-light-200">
          <span class="text-14m">Steam Аккаунт</span>
          <button
            class="text-14sb &hover:text-light-100 transition-colors"
            @click="onWhereGetLogin"
          >
            Где найти логин?
          </button>
        </div>
        <div class="bg-dark-300 rounded-12px relative">
          <div class="relative">
            <input
              v-model="identifier"
              type="text"
              placeholder="Логин Аккаунта Steam..."
              :disabled="loadingPay"
              @focus="onFocusClear('login')"
              class="outline-none w-full py-13px pl-16px pr-12px bg-dark-200 mobile:h-58px rounded-12px border text-20m <mobile:text-14m placeholder:text-light-0/30 &hover:bg-dark-100 disabled:bg-dark-200! transition-colors focus:bg-dark-0!"
              :class="errors.login ? 'border-colors-red' : 'border-dark-300 focus:border-blue-150'"
            />
            <div v-if="loadingPay" class="absolute top-1/2 -translate-y-1/2 right-20px opacity-60">
              <Spinner class="w-16px" />
            </div>
          </div>
          <div
            v-if="lastIdentifierRefill"
            class="py-12px px-16px flex items-center justify-between gap-x-36px"
          >
            <button
              class="flex items-center gap-x-8px text-14sb text-light-100 &hover:text-light-0 transition-colors overflow-hidden"
              @click="onLastIdentifierRefill"
            >
              <template v-if="loadingUser">
                <Skeleton class="w-16px aspect-square rounded-full bg-dark-400" />
                <Skeleton class="w-72px h-14px rounded-2px bg-dark-400" />
              </template>
              <template v-else>
                <i class="i-custom-user text-16px flex-shrink-0"></i>
                <span class="truncate">{{ lastIdentifierRefill }}</span>
              </template>
            </button>
            <div
              v-tippy="'Последний пополненный вами аккаунт'"
              class="flex items-center gap-x-8px text-14m text-light-200 whitespace-nowrap"
            >
              <i class="i-custom-history-1 text-16px flex-shrink-0"></i>
              Последний аккаунт
            </div>
          </div>
        </div>
        <div v-if="errors.login" class="text-14m text-colors-red ml-2px">{{ errors.login }}</div>
      </div>

      <div class="flex flex-col gap-y-8px">
        <span class="text-14m text-light-200">Методы оплаты</span>
        <div class="grid grid-cols-3 gap-8px">
          <template v-if="loading">
            <Skeleton class="h-94px rounded-12px bg-dark-200" />
            <Skeleton class="h-94px rounded-12px bg-dark-200" />
            <Skeleton class="h-94px rounded-12px bg-dark-200" />
          </template>
          <MethodPayment
            v-for="method in paymentMethods"
            :key="method.id"
            :img="`/img/methods-payment/${method.logo}.png`"
            :title="method.name"
            :description="method.description"
            :active="selectedMethodId === method.id"
            @click="selectedMethodId = method.id"
          />
        </div>
      </div>

      <div class="flex flex-col gap-y-8px">
        <span class="text-14m text-light-200"
          >Сумма {{ visibleAmountReplenish ? 'Пополнения' : 'Оплаты' }}</span
        >
        <label
          class="flex items-center gap-x-8px pl-16px pr-12px bg-dark-200 h-58px rounded-12px border text-20m &hover:bg-dark-100 transition-colors focus-visible:bg-dark-0! focus-within:bg-dark-0!"
          :class="[
            loadingPay ? 'pointer-events-none' : '',
            errors.amount
              ? 'border-colors-red'
              : 'border-dark-300 focus-visible:border-blue-150 focus-within:border-blue-150'
          ]"
        >
          <span class="text-light-100 select-none">₽</span>
          <InputMoney
            v-model="amount"
            placeholder="0"
            class="placeholder:text-light-0/30"
            :min="0"
            :disabled="loadingPay"
            :decimalPlaces="0"
            selectFocus
            @focus="onFocusClear('amount')"
          />
          <div class="flex gap-x-4px">
            <ButtonTag @click="onSelectAmount(100)" :disabled="loadingPay">100₽</ButtonTag>
            <ButtonTag @click="onSelectAmount(500)" :disabled="loadingPay">500₽</ButtonTag>
            <ButtonTag @click="onSelectAmount(1000)" :disabled="loadingPay">1000₽</ButtonTag>
          </div>
        </label>
        <div v-if="errors.amount" class="text-14m text-colors-red ml-2px">{{ errors.amount }}</div>
        <!-- <div class="mt-8px flex items-center justify-between text-14m text-light-100">
          <div class="flex items-center gap-x-8px">
            <svg width="20" viewBox="0 0 20 20" class="stat-circle -rotate-90 -mr-2px">
              <circle
                cx="10"
                cy="10"
                r="8"
                fill="none"
                stroke-width="2"
                class="stroke-colors-green-alpha"
              ></circle>
              <circle
                cx="10"
                cy="10"
                r="8"
                fill="none"
                stroke-width="2"
                data-percentage="10"
                class="stroke-colors-green"
                stroke-dasharray="51 51"
                :stroke-dashoffset="percentCalculated"
                stroke-linecap="round"
              ></circle>
            </svg>
            <span>Еще 7382₽ для скидки 4%</span>
            <i class="i-custom-info text-16px"></i>
          </div>
          <div>Сейчас - 0%</div>
        </div> -->
      </div>
    </div>
    <div
      class="mt-2px mb-20px py-16px px-20px rounded-16px bg-dark-100 flex items-center justify-between"
    >
      <button
        class="text-light-100 flex items-center gap-x-8px text-16sb &hover:text-light-0 transition-colors"
        @click="onChangeAmountReplenish"
      >
        <i class="i-custom-swap text-16px"></i>
        Вы {{ visibleAmountReplenish ? 'заплатите' : 'получите' }}
      </button>
      <div class="text-light-0 text-20sb">
        {{
          amount
            ? (visibleAmountReplenish
                ? amount * selectedMethodCommission
                : amount / selectedMethodCommission
              )
                .toLocaleString('ru-RU', {
                  maximumFractionDigits: 2
                })
                .replace(',', '.')
            : 0
        }}
        ₽
      </div>
    </div>
    <Button
      big
      class="mb-16px"
      @click="onPay"
      :loading="loadingPay"
      :disabled="loading || !enabled"
    >
      Перейти к оплате
    </Button>
    <p v-if="!loading && !enabled" class="text-14m text-colors-red text-center">
      Пополнение по логину временно недоступно
    </p>
    <p v-else class="text-14m text-light-200 text-center">
      Нажимая “Перейти к оплате”, вы подтверждаете, что указали логин Steam, а не никнейм
    </p>
  </section>
</template>

<style scoped></style>
