<script setup lang="ts">
import { onMounted, ref } from 'vue'

const props = defineProps<{
  escOff?: boolean
  iconClose?: boolean
  stopCloseOutside?: boolean
}>()

const emit = defineEmits<{
  close: []
}>()

const refModalWrapper = ref<HTMLElement>()
const contentVisible = ref(false)

const startClickIsActive = ref(false)
const endClick = (event: Event) => {
  if (props.stopCloseOutside) return

  if (startClickIsActive.value && event.target === refModalWrapper.value) {
    contentVisible.value = false
  }
  startClickIsActive.value = false
}
const startClick = (event: Event) => {
  if (props.stopCloseOutside) return

  if (event.target === refModalWrapper.value) {
    startClickIsActive.value = true
  }
}

onMounted(() => {
  contentVisible.value = true
  const scrollWidth = window.innerWidth - document.body.clientWidth
  document.body.classList.add('lock')
  document.body.style.paddingRight = `${scrollWidth}px`
  refModalWrapper.value?.focus()
})

function setInvisible() {
  contentVisible.value = false
}
</script>

<template>
  <div
    ref="refModalWrapper"
    class="modal-wrapper"
    tabindex="0"
    @pointerup="endClick"
    @pointerdown="startClick"
    @keydown.esc="escOff ? '' : setInvisible()"
  >
    <Transition
      appear
      :duration="{ enter: 500, leave: 100 }"
      name="modal-content"
      @after-leave="emit('close')"
    >
      <div v-if="contentVisible" class="modal-content">
        <slot :setInvisible="setInvisible" />
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.modal-wrapper:focus {
  outline: none;
}

.modal-content {
  width: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.modal-content::-webkit-scrollbar {
  display: none;
}

.modal-content-enter-active {
  transition: scale 0.5s ease;
}
.modal-content-enter-from {
  scale: 0.8;
}
.modal-content-leave-active {
  transition: scale 1s ease;
}
.modal-content-leave-to {
  scale: 0.8;
}
</style>
