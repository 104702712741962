<script setup lang="ts">
import ModalWrapper from './ModalWrapper.vue'

const modelValue = defineModel<boolean>({ required: true, default: false })

const emit = defineEmits<{
  unlock: []
}>()

defineProps<{
  escOff?: boolean
  iconClose?: boolean
  stopCloseOutside?: boolean
}>()

function unlock() {
  document.body.style.paddingRight = '0px'
  document.body.classList.remove('lock')
  emit('unlock')
}
</script>

<template>
  <Teleport to="body">
    <Transition appear @after-leave="unlock" name="modal-wrapper">
      <ModalWrapper
        v-if="modelValue"
        :esc-off="escOff"
        :icon-close="iconClose"
        :stop-close-outside="stopCloseOutside"
        @close="modelValue = false"
        v-slot="{ setInvisible }"
        class="<mobile:w-full"
      >
        <slot :setInvisible="setInvisible" />
      </ModalWrapper>
    </Transition>
  </Teleport>
</template>

<style scoped>
.modal-wrapper-enter-active,
.modal-wrapper-leave-active {
  transition: opacity 0.3s ease;
}

.modal-wrapper-enter-from,
.modal-wrapper-leave-to {
  opacity: 0;
}
</style>

<style>
.lock {
  overflow: hidden;
}
</style>
