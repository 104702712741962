import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '@/views/RefillSteam/LoginView.vue'
// import SkinsView from '@/views/RefillSteam/SkinsView.vue'
// import GiftView from '@/views/RefillSteam/GiftView.vue'
import HistoryView from '@/views/History/HistoryView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '',
      name: 'refill-login',
      component: HomeView,
      children: [
        {
          path: '/',
          name: 'refill-login',
          component: LoginView
        }
        // {
        //   path: '/refill-skins',
        //   name: 'refill-skins',
        //   component: SkinsView
        // },
        // {
        //   path: '/refill-gift',
        //   name: 'refill-gift',
        //   component: GiftView
        // }
      ]
    },
    {
      path: '/history/:id',
      name: 'history',
      component: HistoryView
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/'
    }
  ]
})

router.afterEach((to, from) => {
  if (typeof ym !== 'undefined') {
    ym(98075596, 'hit', to.fullPath)
  }
})

export default router
