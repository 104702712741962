import { ref, type Ref } from 'vue'

export function useDropdown(target: Ref<HTMLElement | undefined>) {
   const isActive = ref(false)
   const clickElement = ref<HTMLElement>()

   function close() {
      isActive.value = false
      document.removeEventListener('click', outsideClick, true)
   }
   function outsideClick(event: MouseEvent) {
      if (
         (target.value && event.composedPath().includes(target.value)) ||
         (clickElement.value && event.composedPath().includes(clickElement.value))
      ) {
         return
      }

      close()
   }
   async function open(event: Event) {
      clickElement.value = event.target as HTMLElement

      if (isActive.value) {
         close()
      } else {
         document.addEventListener('click', outsideClick, true)
         isActive.value = true
      }
   }

   return {
      isActive,
      open
   }
}
