<script setup lang="ts">
import { ref } from 'vue'
import { useDropdown } from '@/composables/dropdown'
import Burger from '@/components/UI/Burger.vue'
import Button from '@/components/UI/Button.vue'
import posthog from 'posthog-js'
import { modals } from '@/components/Modal'

const isActiveMenu = ref(false)

const refBurgerMenu = ref<HTMLElement>()
const { isActive: isActiveBurgerMenu, open: openBurgerMenu } = useDropdown(refBurgerMenu)

function openMenu(event: Event) {
  isActiveMenu.value = !isActiveMenu.value
  openBurgerMenu(event)
}

const user = ref(true)

function openHistory() {
  posthog.capture('click:header:open_history')
  modals.History()
  isActiveBurgerMenu.value = false
}
</script>

<template>
  <header
    class="header relative z-10 flex items-center gap-x-32px laptop:py-16px px-64px flex-shrink-0 h-72px <laptop:h-64px <laptop:px-24px <laptop:bg-dark-0 <tablet:h-auto <tablet:px-0 <tablet:pt-16px <tablet:gap-y-20px <tablet:flex-wrap <tablet:justify-between"
  >
    <router-link
      :to="{ name: 'refill-login' }"
      class="text-16sb flex items-center gap-x-8px <tablet:ml-20px"
    >
      <i class="i-custom-steam-plus text-20px text-blue-100"></i>
      SteamWarp
    </router-link>

    <nav
      class="flex-auto flex items-center gap-x-32px <tablet:gap-x-28px <mobile:gap-x-24px <tablet:order-last <tablet:basis-full swiper-mobile"
    >
      <router-link :to="{ name: 'refill-login' }" class="link" active-class="link-active">
        <i class="i-custom-steam text-16px"></i>
        Пополнение Steam
      </router-link>
      <button class="link" @click="openHistory">
        <i class="i-custom-history-1 text-16px flex-shrink-0"></i>История Покупок
      </button>
      <div class="link cursor-not-allowed opacity-30">
        <img
          src="/img/gift-market.png"
          srcset="/img/gift-market@2.png 2x"
          alt="gift-market"
          class="w-27px h-25px"
        />
        <span class="text-14sb text-light-200 flex-shrink-0">Гифты, Маркет</span>
        <div class="py-2px px-4px rounded-full bg-dark-400 text-12sb">Скоро</div>
      </div>
      <!-- <router-link :to="{ name: 'test' }" class="link" active-class="link-active">
        <i class="i-custom-gift text-16px"></i>
        Покупка гифтов
      </router-link>
      <router-link :to="{ name: 'test' }" class="link" active-class="link-active">
        <i class="i-custom-gamepad text-16px"></i>
        Пополнение Игр
      </router-link> -->
    </nav>
    <!-- 
    <div v-if="user" class="flex items-center gap-x-8px relative <tablet:mr-20px">
      <button
        class="py-9px px-16px rounded-full bg-dark-400 flex items-center gap-x-8px shadow-user <laptop:hidden relative z-2 &hover:bg-dark-500 transition-colors"
        :class="isActiveBurgerMenu ? 'bg-dark-500' : ''"
        @click="openMenu"
      >
        <div class="w-20px aspect-square bg-black rounded-full flex-shrink-0"></div>
        <span class="text-14sb">hsyyy</span>
        <i
          class="i-custom-chevron-down text-16px text-light-100 flex-shrink-0 transition-transform duration-300"
          :class="isActiveBurgerMenu ? '-rotate-180' : ''"
        ></i>
      </button>

      <div class="w-24px aspect-square bg-black rounded-full flex-shrink-0 laptop:hidden"></div>
      <Burger v-model="isActiveBurgerMenu" class="laptop:hidden" @click="openMenu" />

      <Transition
        appear
        enter-from-class="-translate-y-10px  opacity-0"
        leave-to-class="-translate-y-10px opacity-0"
      >
        <div
          ref="refBurgerMenu"
          v-show="isActiveBurgerMenu"
          class="p-4px rounded-16px bg-dark-200 flex flex-col absolute top-[calc(100%+8px)] right-0 z-10 w-max transition-all duration-300 will-change-transform"
        >
          <button
            class="py-10px px-12px rounded-12px flex items-center gap-x-8px text-14sb text-light-100 &hover:text-light-0 transition-colors"
            @click="openHistory"
          >
            <i class="i-custom-history-1 text-16px flex-shrink-0"></i>
            История Покупок
          </button>
          <div
            class="py-10px px-12px rounded-12px flex items-center gap-x-8px text-14sb text-light-100 &hover:text-light-0 transition-colors"
          >
            <i class="i-custom-logout text-16px flex-shrink-0"></i>
            Выйти
          </div>
        </div>
      </Transition>
    </div>

    <Button v-else class="<tablet:mr-20px">Войти</Button> -->
  </header>
</template>

<style scoped>
.link {
  --uno: 'flex items-center gap-x-8px text-14sb text-light-200 flex-shrink-0';
  --uno: '<tablet:pb-16px';
}

@media (any-hover: hover) {
  .link:not(.link-active):hover {
    --uno: 'text-light-100 transition-colors';
  }
}

.link-active {
  --uno: 'text-light-0 <tablet:border-b-2 border-blue-100 <tablet:pb-14px';
}

@screen laptop {
  .shadow-user {
    box-shadow:
      0px -1px 0px 0px #343434 inset,
      0px 1px 0px 0px #3f3f3f inset;
  }
}

@screen lt-laptop {
  .header::after {
    content: '';
    --uno: 'absolute bottom-0 left-0 right-0 h-1px bg-dark-200 -z-1';
  }
}

@screen lt-tablet {
  .link {
    scroll-snap-align: start;
  }
}
</style>
