import { ref } from 'vue'
import { user } from '@/store/user'
import { apolloClient } from '@/main'
import {
  type SiteSettingsUpdatedSubscription,
  SiteSettingsUpdatedDocument,
  SiteSettingsDocument,
  OrderUpdatedDocument,
  UserDocument,
  OrdersDocument
} from '@/gql/generate/graphql'
import { errorNotification } from '@/composables/errors'

export const siteSettings = ref<SiteSettingsUpdatedSubscription['siteSettingsUpdated']>()
export const siteSettingsLoading = ref(true)

export function initSubscription() {
  apolloClient
    .query({
      query: SiteSettingsDocument
    })
    .then((result) => {
      siteSettings.value = result.data.siteSettings
      siteSettingsLoading.value = false

      const subscription = apolloClient.subscribe({
        query: SiteSettingsUpdatedDocument
      })

      subscription.subscribe({
        next(result) {
          if (result.data?.siteSettingsUpdated) {
            siteSettings.value = result.data.siteSettingsUpdated
          }
        },
        error(error) {
          errorNotification(error)
        }
      })
    })
    .catch((error) => {
      errorNotification(error)
    })

  apolloClient
    .query({
      query: UserDocument
    })
    .then((result) => {
      if (result.data.user) {
        user.value = result.data.user
      }
      const subOrderUpdate = apolloClient.subscribe({
        query: OrderUpdatedDocument
      })
      apolloClient
        .query({
          query: OrdersDocument
        })
        .catch((error) => {
          errorNotification(error)
        })
      subOrderUpdate.subscribe({
        next(result) {
          const updatedOrder = result.data?.orderUpdated
          if (updatedOrder) {
            apolloClient.cache.updateQuery({ query: OrdersDocument }, (data) => {
              if (data) {
                let foundOrder = data.orders.find((order) => order.id === updatedOrder.id)

                if (foundOrder) {
                  foundOrder = { ...foundOrder, ...updatedOrder }
                  return data
                } else {
                  return {
                    ...data,
                    orders: [updatedOrder, ...data.orders]
                  }
                }
              }
            })
          }
        },
        error(error) {
          errorNotification(error)
        }
      })
    })
    .catch((error) => {
      errorNotification(error)
    })
}
