<script setup lang="ts"></script>

<template>
  <section
    class="text-14sb flex items-center gap-x-24px text-light-200 mt-24px mb-20px <laptop:my-20px <laptop:grid grid-cols-3 <laptop:w-full"
  >
    <router-link
      :to="{ name: 'refill-login' }"
      class="refill-steam-tabs-link"
      active-class="refill-steam-tabs-link-active"
    >
      <div class="refill-steam-tabs-icon">
        <i class="i-custom-user"></i>
      </div>

      По Логину
    </router-link>
    <div v-tippy="`Скоро появится`" class="refill-steam-tabs-link cursor-not-allowed">
      <div class="refill-steam-tabs-icon">
        <i class="i-custom-pistol"></i>
      </div>
      Скинами
    </div>
    <div v-tippy="`Скоро появится`" class="refill-steam-tabs-link cursor-not-allowed">
      <div class="refill-steam-tabs-icon">
        <i class="i-custom-gc"></i>
      </div>
      Гифткартой
    </div>
    <!-- <router-link
      :to="{ name: 'refill-skins' }"
      class="refill-steam-tabs-link"
      active-class="refill-steam-tabs-link-active"
    >
      <div class="refill-steam-tabs-icon">
        <i class="i-custom-pistol"></i>
      </div>

      Скинами
    </router-link> -->

    <!-- <router-link
      :to="{ name: 'refill-gift' }"
      class="refill-steam-tabs-link"
      active-class="refill-steam-tabs-link-active"
    >
      <div class="refill-steam-tabs-icon">
        <i class="i-custom-gc"></i>
      </div>

      Гифткартой
    </router-link> -->
  </section>
</template>

<style scoped>
.refill-steam-tabs-link {
  --uno: 'flex items-center gap-x-8px transition-colors';
  --uno: '<laptop:flex-col <laptop:gap-y-4px';
}
.refill-steam-tabs-icon {
  --uno: 'text-16px ';
  --uno: '<laptop:text-18px <laptop:py-12px <laptop:px-20px <laptop:rounded-12px <laptop:bg-dark-100';
}
.refill-steam-tabs-link-active {
  --uno: 'text-light-0';
}

.refill-steam-tabs-link-active .refill-steam-tabs-icon {
  --uno: '<laptop:bg-dark-400';
}

.refill-steam-tabs-link:not(.refill-steam-tabs-link-active) {
  --uno: '&hover:text-light-100';
}
</style>
