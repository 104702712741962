<script setup lang="ts"></script>

<template>
  <div class="flex gap-x-4px">
    <div class="loader-dot"></div>
    <div class="loader-dot"></div>
    <div class="loader-dot"></div>
  </div>
</template>

<style scoped>
.loader-dot {
  width: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #000;
  animation: flash 0.8s ease-out infinite alternate;
}

.loader-dot:nth-child(1) {
  animation-delay: 0.25s;
}

.loader-dot:nth-child(2) {
  animation-delay: 0.5s;
}

@keyframes flash {
  0% {
    background-color: #000;
  }
  100% {
    background-color: rgba(67, 67, 67, 0.133);
  }
}
</style>
