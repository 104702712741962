import { ref } from 'vue'

type NotificationsMessage = {
  type: 'notify' | 'success' | 'error'
  text: string
}

type NotificationsData = NotificationsMessage & { timer: number; id: number }

const notificationCount = ref(0)
export const notifications = ref<Array<NotificationsData>>([])

export function notificationAdd(
  type: NotificationsMessage['type'],
  text: NotificationsMessage['text'],
  duration = 5000
) {
  notificationCount.value++
  const id = notificationCount.value
  notifications.value.push({
    id,
    type,
    text,
    timer: setTimeout(() => {
      notifications.value = notifications.value.filter((item) => item.id !== id)
    }, duration)
  })
}

// export function notificationError(error: any) {
//    notificationAdd('error', handleError(error).text)
// }
