<script setup lang="ts">
import { ref, computed } from 'vue'

import { type OrderStatus } from '@/gql/generate/graphql'

import WeaponBox from '@/components/WeaponBox.vue'
import Button from '@/components/UI/Button.vue'

const props = defineProps<{
  refill: 'login' | 'skins' | 'gifts'
  name: string
  avatar?: string
  amount: number
  status: OrderStatus
  weapons?: { img: string; status: 'received' | 'sending' | 'error' }[]
}>()

const weaponsFirst = computed(() => props.weapons?.slice(0, 3))
</script>

<template>
  <div
    class="py-20px px-24px <mobile:px-20px not-last:border-b border-dark-200 flex flex-col gap-y-16px cursor-pointer &hover:bg-dark-200 duration-100 last:rounded-b-16px"
  >
    <div class="flex items-start gap-x-12px">
      <div class="p-12px rounded-12px bg-dark-300">
        <i class="i-custom-steam text-16px"></i>
      </div>

      <div class="grid grid-cols-[1fr_auto] w-full gap-x-20px">
        <div class="mb-4px flex items-center gap-x-4px text-16sb overflow-hidden">
          <span class="truncate">{{ name }}</span>
          <i class="i-custom-user text-16px flex-shrink-0"></i>
        </div>

        <div
          class="flex items-center gap-x-8px text-16sb whitespace-nowrap"
          :class="status === 'failed' ? 'text-colors-red' : ''"
        >
          {{
            amount
              .toLocaleString('ru-RU', {
                maximumFractionDigits: 2
              })
              .replace(',', '.')
          }}
          ₽

          <template v-if="status === 'created'">
            <i
              class="i-custom-order-approve text-18px text-colors-yellow"
              v-tippy="'Заказ создан'"
              @click.stop
            ></i>
          </template>
          <template v-else-if="status === 'failed'">
            <i
              class="i-custom-attention text-18px text-colors-red"
              v-tippy="'Произошла ошибка.'"
              @click.stop
            ></i>
          </template>
          <template v-else-if="status === 'processing'">
            <i
              class="i-custom-loader text-18px text-colors-yellow animate-spin"
              v-tippy="'Происходит отправка'"
              @click.stop
            ></i>
          </template>
          <template v-else-if="status === 'finished'">
            <i
              class="i-custom-checkmark text-18px text-colors-green"
              v-tippy="'Деньги отправлены'"
            ></i>
          </template>
        </div>

        <span class="text-14m text-light-100 col-span-2">
          <template v-if="refill === 'skins'"> Пополнение скинами </template>
          <template v-else-if="refill === 'gifts'"> Пополнение гифтами </template>
          <template v-else-if="refill === 'login'"> Пополнение по логину </template>
        </span>
      </div>
    </div>
    <template v-if="weapons">
      <div class="relative">
        <div class="rounded-12px grid grid-cols-3 gap-1px overflow-hidden">
          <WeaponBox
            v-for="(weapon, index) in weaponsFirst"
            :key="index"
            :img="weapon.img"
            :status="weapon.status"
            class="bg-dark-200 p-8px last:rounded-r-inherit"
          />
        </div>
        <button
          v-if="weapons.length > 3"
          class="py-2px px-6px rounded-full bg-dark-400 text-12sb absolute -top-7px -right-8px"
        >
          +{{ weapons.length - 3 }}
        </button>
      </div>
      <Button color="dark" @click.stop>
        <i class="i-custom-refresh text-16px"></i>
        Переотправить Скины
      </Button>
    </template>
  </div>
</template>

<style scoped></style>
