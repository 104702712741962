<script setup lang="ts"></script>

<template>
  <button class="button-tag">
    <slot />
  </button>
</template>

<style scoped>
.button-tag {
  --uno: 'py-6px px-12px rounded-full flex items-center gap-x-6px bg-dark-400 text-14sb text-light-0 transition-all whitespace-nowrap select-none';
  box-shadow:
    0px -1px 0px 0px #343434 inset,
    0px 1px 0px 0px #3f3f3f inset;
}

@media (hover: hover) and (pointer: fine) {
  .button-tag:hover {
    --uno: 'bg-dark-500 text-opacity-60';
    box-shadow:
      0px -1px 0px 0px rgba(52, 52, 52, 0.6) inset,
      0px 1px 0px 0px #454545 inset;
  }
}

.button-tag:active {
  --uno: 'bg-dark-400 text-opacity-100 opacity-30';
  box-shadow:
    0px -0.95px 0px 0px #343434 inset,
    0px 0.95px 0px 0px #3f3f3f inset;
}
</style>
