import posthog from 'posthog-js'
import { type App } from 'vue'

export default {
  install(app: App) {
    app.config.globalProperties.$posthog = posthog.init(
      'phc_XXZSVb8dZSy7zui5dIvGXSdcHZStk81om2ezMBVnek0',
      {
        api_host: 'https://eu.i.posthog.com'
      }
    )
  }
}
