<script setup lang="ts"></script>

<template>
  <div class="spinner"></div>
</template>

<style scoped>
.spinner {
  width: 16px;
  display: inline-block;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  vertical-align: middle;
  aspect-ratio: 1;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
</style>
