<script setup lang="ts">
import Button from '@/components/UI/Button.vue'
import posthog from 'posthog-js'

function openTelegram() {
  posthog.capture('click:telegram_banner:open_telegram')
}
</script>

<template>
  <section
    class="h-211px mobile:rounded-16px bg-[#6A12FA] relative z-1 p-32px <tablet:p-20px overflow-hidden"
  >
    <h3 class="heading-24sb">Канал SteamWarp в Telegram</h3>
    <p class="text-14m max-w-319px mt-12px mb-20px <tablet:max-w-200px">
      Публикуем самые свежие новости мира игр, розыгрыши и акции
    </p>
    <Button as="a" href="https://t.me/steamwarp" target="_blank" @click="openTelegram"
      >Подписаться</Button
    >

    <img
      src="/img/banners/steamwarp-telegram.png"
      srcset="/img/banners/steamwarp-telegram@2.png 2x"
      alt="steamwarp-telegram"
      class="absolute top-0 right-0 bottom-0 -z-1 rounded-inherit max-w-720px h-full <tablet:-right-86px"
    />
  </section>
</template>

<style scoped></style>
