<script setup lang="ts">
import { ref, onUnmounted } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { PublicOrdersDocument } from '@/gql/generate/graphql'
import Skeleton from '../UI/Skeleton.vue'
import HistoryRefillItem from './HistoryRefillItem.vue'
import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules'
import 'swiper/css/bundle'

Swiper.use([Autoplay])

const refSwiper = ref<HTMLElement>()
let swiper: Swiper | null

const { result, loading, onResult } = useQuery(PublicOrdersDocument)

onResult((result) => {
  if (result?.data?.publicOrders) {
    if (refSwiper.value) {
      swiper = new Swiper(refSwiper.value, {
        slidesPerView: 'auto',
        spaceBetween: '8',
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        autoplay: false,
        loop: false,
        breakpoints: {
          769: {
            loop: true,
            autoplay: {
              delay: 2000,
              pauseOnMouseEnter: true
            },
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0
          }
        }
      })
    }

    if (window.innerWidth >= 769) {
      swiper?.autoplay?.start()
    }
  }
})

onUnmounted(() => {
  if (swiper) {
    swiper.destroy()
    swiper = null
  }
})
</script>

<template>
  <section v-if="result?.publicOrders" ref="refSwiper" class="swiper">
    <div class="swiper-wrapper">
      <div
        v-for="(order, index) in result.publicOrders"
        :key="`global-order-${index}`"
        class="swiper-slide w-160px! <tablet:w-174px!"
      >
        <HistoryRefillItem
          :amount="order.amount"
          :name="order.identifier"
          :date="order.createdAt"
        />
      </div>
    </div>
  </section>
  <section v-else class="grid grid-cols-4 gap-x-8px px-16px">
    <Skeleton v-for="i in 4" :key="i" class="h-[84.79px] bg-dark-100 rounded-12px" />
  </section>
</template>

<style scoped></style>
