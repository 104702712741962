<script setup lang="ts">
import Button from '@/components/UI/Button.vue'
import posthog from 'posthog-js'

function openChat() {
  posthog.capture('click:partners:open_chat')
}
</script>

<template>
  <section
    class="p-20px <tablet:px-16px <tablet:py-12px flex items-center <mobile:flex-col gap-x-16px <mobile:gap-y-8px rounded-16px bg-dark-100"
  >
    <div class="mobile:contents flex items-start gap-x-12px">
      <div
        class="flex-shrink-0 w-44px <tablet:w-40px aspect-square rounded-full border border-colors-yellow-alpha p-3px"
      >
        <div
          class="partners-bg-icon w-full aspect-square rounded-full border border-colors-yellow-alpha flexc"
        >
          <i class="i-custom-info text-20px text-colors-yellow"></i>
        </div>
      </div>
      <p class="text-14m <tablet:text-12m text-light-100">
        Предоставим вашему проекту удобное и быстрое решение по пополнению баланса Steam
      </p>
    </div>
    <Button
      color="dark"
      class="flex-shrink-0 <tablet:text-12sb <mobile:w-full px-32px!"
      onclick="Chatra('openChat', true)"
      @click="openChat"
    >
      Написать нам
    </Button>
  </section>
</template>

<style scoped>
.partners-bg-icon {
  background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(36, 36, 36, 0.3) 0%,
      rgba(50, 44, 29, 0.3) 100%
    ),
    #242424;
}
</style>
