<script setup lang="ts">
import { ref } from 'vue'
import { modals } from '../'
import Modal from '../Modal.vue'

const modalIsActive = ref(true)

function onCloseModal() {
  modals.clear()
}
</script>

<template>
  <Modal v-model="modalIsActive" @unlock="onCloseModal" v-slot="{ setInvisible }">
    <section
      class="bg-dark-100 rounded-16px w-420px <mobile:w-full will-change-transform p-36px <mobile:px-16px flex items-center flex-col gap-y-24px relative"
    >
      <span class="heading-24sb">Где взять логин Steam?</span>

      <button
        class="i-custom-close text-16px text-light-200 &hover:text-light-100 transition-colors absolute top-16px right-16px"
        @click.stop="setInvisible"
      ></button>

      <div class="flex flex-col items-center gap-y-4px">
        <div class="w-254px h-66px">
          <img src="/img/where-get-login.png" alt="where-get-login" class="w-full" />
        </div>

        <a
          href="https://store.steampowered.com/account/"
          target="_blank"
          class="text-colors-yellow/80 text-14sb uppercase &hover:text-colors-yellow trs"
        >
          Найти можно тут</a
        >
      </div>
      <div class="text-light-100 text-16sb">
        Логин — это то, что вы указываете при входе в Steam аккаунт. Если вы укажите неверный логин, то
        средства могут уйти другому пользователю.
      </div>
    </section>
  </Modal>
</template>

<style scoped></style>
