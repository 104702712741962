<script setup lang="ts">
import dayjs from 'dayjs'

const props = defineProps<{
  amount: number
  name: string
  date: Date | string
}>()

const formatDate = () => {
  const orderDate = dayjs(props.date)
  const today = dayjs()

  if (orderDate.isSame(today, 'day')) {
    return orderDate.format('HH:mm')
  } else {
    return orderDate.format('DD.MM')
  }
}
</script>

<template>
  <div
    class="py-16px px-20px rounded-12px bg-dark-100 flex flex-col gap-y-8px cursor-pointer &hover:bg-dark-200 transition-colors flex-shrink-0"
  >
    <span class="text-16sb text-light-0">{{ Math.round(amount).toLocaleString() }}₽</span>
    <div class="flex items-center justify-between gap-x-4px text-light-200">
      <div class="flex items-center gap-x-8px uppercase text-12m overflow-hidden">
        <img src="/img/user-history.svg" alt="user history" class="w-16px h-16px" />
        <span class="truncate">{{ name }}</span>
      </div>

      <div class="text-12sb">{{ formatDate() }}</div>
    </div>
  </div>
</template>

<style scoped></style>
