import { defineAsyncComponent, shallowRef, type Component } from 'vue'
import ModalHistory from './items/ModalHistory.vue'
import ModalWhereGetLogin from './items/ModalWhereGetLogin.vue'

export const modals = {
  History: () => {
    modalComponent.value = ModalHistory
  },
  WhereGetLogin: () => {
    modalComponent.value = ModalWhereGetLogin
  },
  Contacts: () => {
    modalComponent.value = defineAsyncComponent(() => import('./items/ModalContacts.vue'))
  },
  InvalidSteamLogin: () => {
    modalComponent.value = defineAsyncComponent(() => import('./items/ModalInvalidSteamLogin.vue'))
  },
  clear: () => {
    modalComponent.value = undefined
  }
}

export const modalComponent = shallowRef<Component>()
