<script setup lang="ts">
import { ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    modelValue: number | string
    min?: number
    max?: number
    maxLength?: number
    decimalPlaces?: number
    selectFocus?: boolean
    disabled?: boolean
  }>(),
  {
    decimalPlaces: 10
  }
)

const emit = defineEmits<{
  'update:modelValue': [number | '']
  'update:focus': [boolean]
}>()

const inputValue = ref(props.modelValue)

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue === inputValue.value) return
    inputValue.value = props.modelValue
  }
)

function handler(event: Event) {
  if (event instanceof InputEvent) {
    const element = event.target as HTMLInputElement

    let value = element.value

    if (event.inputType === 'insertFromPaste' && Number.isNaN(Number(value))) {
      inputValue.value = ''
      return
    }

    const decimalPattern =
      props.decimalPlaces && props.decimalPlaces !== 0
        ? `([,.бю]?\\d{0,${props.decimalPlaces}})?`
        : ''
    const regexPattern =
      props.min !== undefined && props.min >= 0
        ? `^\\d+${decimalPattern}$`
        : `^-?$|^-?\\d+${decimalPattern}$`
    const regex = new RegExp(regexPattern, 'g')

    if (!regex.test(value) || (props.maxLength && value.length > props.maxLength)) {
      const start = element.selectionStart ?? 0
      const end = element.selectionEnd ?? 0

      value = value.slice(0, start - 1) + value.slice(end)
    }

    inputValue.value = value.replace(/[,бю]/, '.')

    if (props.max !== undefined && Number(value) > props.max) {
      inputValue.value = props.max
    }

    if (inputValue.value === '-') {
      return
    }

    emit('update:modelValue', inputValue.value ? Number(inputValue.value) : '')
  }
}

function change() {
  if (
    props.min !== undefined &&
    (inputValue.value === '' || Number(inputValue.value) < props.min)
  ) {
    inputValue.value = props.min
    emit('update:modelValue', props.min)
  } else if (inputValue.value === '-') {
    inputValue.value = ''
    emit('update:modelValue', '')
  }
}

function onFocus(event: Event) {
  if (props.selectFocus) {
    const target = event.target as HTMLInputElement
    target.select()
  }

  emit('update:focus', true)
}
</script>

<template>
  <input
    v-model="inputValue"
    type="text"
    inputmode="decimal"
    :disabled="disabled"
    class="outline-none w-full bg-transparent"
    @input="handler"
    @change="change"
    @blur="emit('update:focus', false)"
    @focus="onFocus"
  />
</template>

<style scoped></style>
