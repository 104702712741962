import { reactive } from 'vue'

export const copyObject = reactive({
  x: 0,
  y: 0,
  visible: false
})

export const copy = async (event: MouseEvent, value: number | string) => {
  copyObject.x = event.pageX + 10
  copyObject.y = event.pageY - 20
  copyObject.visible = true

  if (window.isSecureContext) {
    await navigator.clipboard.writeText(String(value))
  } else {
    console.log('Copy to clipboard not supported HTTP')
  }

  setTimeout(() => {
    copyObject.visible = false
  }, 300)
}
