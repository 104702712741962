import * as Sentry from '@sentry/vue'
import { notificationAdd } from '@/store/toast'
import { ApolloError } from '@apollo/client'

export function errorApolloHandler(error: unknown) {
  let errorCode: undefined | string
  let errorMessage: undefined | string

  if (error instanceof ApolloError) {
    if (error.graphQLErrors) {
      errorCode = error.graphQLErrors[0].extensions?.code as string
      errorMessage = error.graphQLErrors[0].extensions?.message as string | undefined
    }
  }

  return { errorCode, errorMessage }
}

const errorMessages: Record<string, string> = {
  INVALID_STEAM_LOGIN: 'Неверный steam логин',
  INVALID_AMOUNT: 'Неправильная сумма заказа',
  ORDER_NOT_FOUND: 'Заказ не найден',
  PROVIDER_NOT_FOUND: 'Провайдер не найден',
  RATE_LIMIT_EXCEEDED: 'Превышен лимит запросов',
  TEMPORARY_UNAVAILABLE: 'Сервис временно недоступен',
  UNAUTHORIZED: 'Вы не авторизованы'
}

export function errorNotification(error: unknown) {
  const { errorCode, errorMessage } = errorApolloHandler(error)

  if (errorCode && errorCode in errorMessages) {
    return notificationAdd('error', errorMessages[errorCode])
  }

  if (errorMessage) {
    return notificationAdd('error', errorMessage)
  }

  Sentry.captureException(error)

  return notificationAdd('error', 'Неизвестная ошибка. Обратитесь в техподдержку')
}
