<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'

import { useQuery } from '@vue/apollo-composable'
import { OrdersDocument } from '@/gql/generate/graphql'

import { modals } from '../'
import Modal from '../Modal.vue'

import HistoryBox from './HistoryBox.vue'
import Skeleton from '@/components/UI/Skeleton.vue'

// TODO: не забыть сделать логику со скинами
// TODO: добавить пагинацию, fetchMore

const router = useRouter()
const modalIsActive = ref(true)

const { result } = useQuery(OrdersDocument, undefined, {
  fetchPolicy: 'cache-first'
})

const ordersByDate = computed(() => {
  if (!result.value?.orders) return

  const today = dayjs().format('DD MMMM')

  const orders = result.value.orders.reduce(
    (acc, order) => {
      const orderDate = dayjs(order.createdAt)
      let dateKey = orderDate.format('DD MMMM')

      if (dateKey === today) {
        dateKey = 'Сегодня'
      } else {
        dateKey = dateKey.replace(/(?:^|\s)\S/g, function (a) {
          return a.toUpperCase()
        })
      }

      if (!acc[dateKey]) {
        acc[dateKey] = []
      }

      acc[dateKey].push(order)

      return acc
    },
    {} as Record<string, any>
  )

  return orders
})

function onCloseModal() {
  modals.clear()
}

const openOrder = (id: string) => {
  router.push({ name: 'history', params: { id } })
  modalIsActive.value = false
}

const weapons: {
  img: string
  status: 'received' | 'sending' | 'error'
}[] = [
  {
    img: '/img/weapons/1.png',
    status: 'received'
  },
  {
    img: '/img/weapons/2.png',
    status: 'sending'
  },
  {
    img: '/img/weapons/3.png',
    status: 'error'
  },
  {
    img: '/img/weapons/3.png',
    status: 'error'
  }
]
</script>

<template>
  <Modal v-model="modalIsActive" @unlock="onCloseModal" v-slot="{ setInvisible }">
    <section class="bg-dark-100 rounded-16px w-348px <mobile:w-full will-change-transform">
      <div class="px-24px pt-24px pb-16px text-20sb flex items-center justify-between">
        История Заказов
        <button
          class="i-custom-close text-16px text-light-200 &hover:text-light-100 transition-colors -mt-2px"
          @click.stop="setInvisible"
        ></button>
      </div>

      <div class="max-h-550px scroll-y scroll-none">
        <div
          v-if="result?.orders.length === 0"
          class="flexc pt-24px pb-48px text-24px gap-x-8px text-dark-500"
        >
          <i class="i-custom-empty text-28px"></i>
          Заказов нет
        </div>
        <template v-else-if="result?.orders && result.orders.length > 0">
          <template v-for="(orders, date) in ordersByDate" :key="date">
            <div class="px-24px pb-4px text-14m text-light-100 not-first:mt-16px">
              {{ date }}
            </div>
            <HistoryBox
              v-for="order in orders"
              :key="order.id"
              refill="login"
              :name="order.identifier"
              :amount="order.amount"
              :status="order.status"
              @click="openOrder(order.uuid)"
            />
          </template>
        </template>
        <template v-else>
          <Skeleton class="h-93px not-last:border-b border-dark-200" />
          <Skeleton class="h-93px not-last:border-b border-dark-200" />
          <Skeleton class="h-93px not-last:border-b border-dark-200" />
        </template>

        <!-- <HistoryBox
          refill="skins"
          :weapons="weapons"
          name="ffsjke"
          :amount="16743"
          status="error"
          @click="openOrder('2')"
        /> -->

        <!-- <div class="px-24px pt-24px <mobile:px-20px pb-4px text-14m text-light-100">13 Апреля</div> -->
      </div>
    </section>
  </Modal>
</template>

<style scoped></style>
