<script setup lang="ts">
import { ref } from 'vue'
import { siteSettings, siteSettingsLoading } from '@/store'
import Faq from '@/components/Faq.vue'
import Skeleton from '@/components/UI/Skeleton.vue'
import Modal from '@/components/Modal/Modal.vue'
import { modals } from '@/components/Modal'
// import SaleSteam from './components/Sale.vue'

const modalIsActive = ref(false)

function onCloseModal() {
  modalIsActive.value = false
}
</script>

<template>
  <div class="flex flex-col gap-y-8px <tablet:gap-y-4px">
    <!-- <SaleSteam class="<laptop:hidden" /> -->

    <div class="py-16px px-20px rounded-12px bg-dark-100 relative">
      <div class="text-12sb text-light-200 uppercase mb-2px">ПОПОЛНЯЕТЕ ВПЕРВЫЕ?</div>
      <button
        class="text-16sb &hover:text-blue-200 transition-colors"
        @click="modalIsActive = true"
      >
        Откройте инструкцию
      </button>

      <img
        src="/img/instruction.png"
        srcset="/img/instruction@2.png 2x"
        alt="instruction"
        class="w-53px h-56px absolute right-14px top-1/2 -translate-y-1/2"
      />
    </div>
    <div class="px-20px pt-20px pb-4px rounded-12px bg-dark-100">
      <div class="mb-16px text-12sb text-light-200 uppercase">ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</div>

      <Faq
        title="Что такое логин Steam"
        text="Логин вводится при входе в Steam клиент. Он уникальный и его нельзя изменить после регистрации."
      />
      <Faq
        title="Какие страны можно пополнить?"
        text="Все страны, входящие в СНГ. Если вы из Крыма, ЛНР или ДНР, то необходимо выйти из Steam на ПК за 5 минут до оплаты."
      />
      <Faq
        title="Лимиты и ограничения"
        text="Перенесенные из СНГ аккаунты в другие регионы (например, Турцию) пополнить невозможно. Максимальное пополнение баланса на один логин за 24 часа составляет 400$."
      />
      <Faq
        title="Не приходят деньги на баланс"
        text="Если вы корректно указали логин, пополнение произойдет без задержек. Если вам не поступили средства на баланс в течение 10 минут, пожалуйста, обратитесь в Техническую поддержку"
      />
    </div>
    <div class="py-16px px-20px rounded-12px bg-dark-100 grid grid-cols-2 gap-x-20px">
      <div>
        <div class="text-12sb text-light-200 uppercase mb-2px">Пополнений</div>
        <Skeleton v-if="siteSettingsLoading" class="w-60px h-[25.59px] rounded-4px bg-dark-200" />
        <div v-else class="text-16sb">{{ siteSettings?.ordersCount }}</div>
      </div>
      <div>
        <div class="text-12sb text-light-200 uppercase mb-2px">комиссия</div>
        <Skeleton v-if="siteSettingsLoading" class="w-30px h-[25.59px] rounded-4px bg-dark-200" />
        <div v-else class="text-16sb">{{ siteSettings?.commission }}%</div>
      </div>
    </div>

    <Modal v-model="modalIsActive" @unlock="onCloseModal" v-slot="{ setInvisible }">
      <section
        class="bg-dark-100 rounded-16px w-420px <mobile:w-full will-change-transform p-36px <mobile:px-16px flex items-center flex-col gap-y-24px relative"
      >
        <span class="heading-24sb">Инструкция</span>

        <button
          class="i-custom-close text-16px text-light-200 &hover:text-light-100 transition-colors absolute top-16px right-16px"
          @click.stop="setInvisible"
        ></button>

        <div class="flex flex-col gap-y-12px">
          <div class="opacity-70">
            1. Введите свой логин Steam
            <button
              class="text-blue-200 inline-block &hover:text-blue-100 transition-colors"
              @click="modals.WhereGetLogin"
            >
              (как узнать логин?)
            </button>
          </div>
          <div class="opacity-70">2. Выберите метод оплаты</div>
          <div>
            <span class="opacity-70">
              3. Укажите сумму пополнения или сумму оплаты, нажав на значок
            </span>
            &nbsp;
            <i class="i-custom-swap inline-block"></i>
          </div>
          <div class="opacity-70">4. Нажмите кнопку "Перейти к оплате"</div>
          <div class="opacity-70">5. Произведите оплату</div>
          <div class="opacity-70">
            6. Ожидайте пополнения баланса на Steam. <br />
            Баланс будет пополнен в течение минуты
          </div>
        </div>

        <div class="w-54px h-55px">
          <img
            src="/img/steam-coin.png"
            srcset="/img/steam-coin@2.png 2x"
            alt="steam-coin"
            class=""
          />
        </div>
      </section>
    </Modal>
  </div>
</template>

<style scoped></style>
